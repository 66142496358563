@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.flex-vcenter {
		@apply flex items-center;
	}

	.flex-hcenter {
		@apply flex justify-center;
	}

	.flex-center {
		@apply flex items-center justify-center;
	}

	.flex-col-center {
		@apply flex flex-col items-center justify-center;
	}

	.full {
		@apply w-full h-full;
	}

	.screen {
		@apply w-screen h-screen;
	}

	.ezg-red {
		@apply text-[#b72f4a];
	}
}
