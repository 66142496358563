.shake {
	animation: shake 6s;
	animation-iteration-count: infinite;
}

.shake-slower {
	animation: shake 24s;
	animation-iteration-count: infinite;
}

@keyframes shake {
	0% {
		transform: translate(1px, 1px) rotate(0deg);
	}
	9% {
		transform: translate(-1px, -2px) rotate(-1deg);
	}
	18% {
		transform: translate(-3px, 0px) rotate(1deg);
	}
	27% {
		transform: translate(3px, 2px) rotate(0deg);
	}
	36% {
		transform: translate(1px, -1px) rotate(1deg);
	}
	45% {
		transform: translate(-1px, 2px) rotate(-1deg);
	}
	54% {
		transform: translate(-3px, 1px) rotate(0deg);
	}
	63% {
		transform: translate(3px, 1px) rotate(-1deg);
	}
	72% {
		transform: translate(-1px, -1px) rotate(1deg);
	}
	81% {
		transform: translate(1px, 2px) rotate(0deg);
	}
	90% {
		transform: translate(1px, -2px) rotate(-1deg);
	}
	100% {
		transform: translate(1px, 1px) rotate(0deg);
	}
}
